import React from "react";
import Logo from "../../../components/Logo";
import toggleLeft from "../../../assets/img/icons/arrowLeft.svg";
import toggleRight from "../../../assets/img/icons/arrowRight.svg";
import { Tooltip } from "antd";

export const Header = ({ isSidebarClosed, onClick, isClosed }) => {
  return (
    <div className="sidebar_header ">
      <Logo isSidebarClosed={isSidebarClosed} />
    </div>
  );
};

export const Toggle = ({ onClick, isClosed, mode }) => {
  return (
    <Tooltip
      placement={"top"}
      title={isClosed ? "Elargir le bandeau" : "Réduire le bandeau"}
    >
      <div
        className={`sidebar_toggle ${mode === "compta" ? "blue" : "yellow"}`}
        onClick={onClick}
      >
        <img src={isClosed ? toggleRight : toggleLeft} alt="toggle" />
      </div>
    </Tooltip>
  );
};
