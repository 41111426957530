import setupLocatorUI from "@locator/runtime";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StyleProvider } from "@ant-design/cssinjs";
import "./App.scss";
import { ModalsProvider } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { verifyAccount } from "./data/slices/auth";
import { useDarkMode } from "./hooks/useDarkMode";
import Router from "./routes";
import useSize from "./hooks/useWindowsInner";
import ResponsivePage from "./pages/utils/ResponsivePage";

dayjs.locale("fr");

function App() {
  const dispatch = useDispatch();
  const [theme, componentMounted] = useDarkMode();
  const wrapperClass = ` wrapper${theme === "dark" ? " dark" : ""}`;
  const [width, height] = useSize();
  useEffect(() => {
    if (window.location.href.includes("verify-account")) {
      const code = window.location.href.split("verify-account/")[1];

      dispatch(verifyAccount({ code }));
    }
  }, [dispatch]);

  if (process.env.NODE_ENV === "development") {
    setupLocatorUI();
  }

  if (!componentMounted) {
    return <div />;
  }

  return (
    <StyleProvider hashPriority="high">
      <div className={wrapperClass}>
        <SnackbarProvider
          maxSnack={12}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <BrowserRouter>
            <AuthProvider>
              {height < 600 || width < 1056 ? <ResponsivePage /> : <Router />}
              <ModalsProvider />
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </div>
    </StyleProvider>
  );
}

export default App;
