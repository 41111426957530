export const ROW_HEIGHT = 38;
export const HEADER_HEIGHT = 120;
export const LISTING_HEIGHT = 54;

const generateEmptyRow = (key, renderCell) => ({
  key,
  name: "",
  isEmpty: true,
});

function findMaxDepth(obj) {
  if (typeof obj !== "object" || obj === null) {
    return 0;
  }

  let maxDepth = 0;

  for (const key in obj) {
    if (maxDepth < obj[key].length) {
      maxDepth = obj[key].length;
    }
  }

  return maxDepth;
}

function addIdKeyToCategories(categories) {
  return categories.map((category) => {
    const newCategory = { ...category, key: category.id };
    if (category.children) {
      newCategory.children = addIdKeyToCategories(category.children);
    }
    return newCategory;
  });
}

const calculateMonthTotals = (categories, rangesArray) => {
  const calculateTotal = (category, months = {}) => {
    for (const monthsRange of rangesArray) {
      const rangeKey = monthsRange.join(", ");

      for (const month of monthsRange) {
        if (category?.elements?.[month]) {
          if (!months[month]) {
            months[month] = 0;
          }
          for (const item of category.elements[month]) {
            months[month] += item.amount;
            if (month !== rangeKey) {
              if (!months[rangeKey]) {
                months[rangeKey] = 0;
              }
              months[rangeKey] += item.amount;
            }
          }
        }
      }
    }

    if (category.children && category.children.length > 0) {
      for (const subCategory of category.children) {
        calculateTotal(subCategory, months);
      }

      category.children = calculateMonthTotals(category.children, rangesArray);
    }

    return { ...category, ...months };
  };

  return categories.map((category) => calculateTotal(category));
};

function truncateWithThreeDots(inputString, maxLength) {
  return inputString?.length > maxLength
    ? inputString.slice(0, maxLength) + "..."
    : inputString;
}

const generateClassName = (status) => {
  switch (status) {
    case "pointé":
      return "pointed-class";
    case "engagé":
      return "engaged-class";
    case "prévisionnel":
      return "prev-class";
    case "simulation":
      return "simul-class";
    default:
      return "default-class";
  }
};

const generateClassNameCompta = (status, type) => {
  if (type) {
    return "compta-sold-class";
  }
  switch (status) {
    case "réel":
      return "real-class";
    case "simulation":
      return "simul-class";
  }
};

function calculateBalanceSums(array) {
  return array?.reduce(
    (acc, curr) => {
      const synthese = parseFloat(curr.synthese);

      if (synthese > 0) {
        acc.positiveBalance += synthese;
      } else {
        acc.negativeBalance -= synthese;
      }

      acc.finalBalance = acc.positiveBalance - acc.negativeBalance;
      return acc;
    },
    {
      positiveBalance: 0,
      negativeBalance: 0,
      finalBalance: 0,
    }
  );
}

const findById = (id, items) => {
  for (const item of items) {
    if (item.id == id) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const found = findById(id, item.children);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const findIndexById = (id, items) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return i;
    }
  }
  return -1;
};

const findNextItemSameLevel = (id, items) => {
  const parent = items.find(
    (item) => item.children && findIndexById(id, item.children) !== -1
  );
  if (parent) {
    const index = findIndexById(id, parent.children);
    if (index !== -1 && index + 1 < parent.children.length) {
      return parent.children[index + 1];
    }
  }
  return null;
};

const updateSynthesis = (arr1, arr2) => {
  if (!arr2?.elementsSynthese) return "";
  return arr1.map((item) => {
    const matchingSynthesis = arr2?.elementsSynthese?.find((sItem) =>
      item.isRacine
        ? sItem.categoryId === item?.categoryId
        : sItem.categoryId === item?.id
    );
    if (item.isRacine)
      return { ...item, synthese: matchingSynthesis?.synthese };

    if (matchingSynthesis) {
      item.synthese = matchingSynthesis.synthese;
    }

    if (matchingSynthesis?.synthese === 0) {
      item.synthese = 0;
    }
    if (!matchingSynthesis) {
      item.synthese = null;
    }
    if (item.children && item.children.length > 0) {
      item.children = updateSynthesis(item.children, arr2);
      item.synthese += item.children.reduce(
        (sum, child) => sum + (child.isRacine ? 0 : child.synthese || 0),
        0
      );
    }

    if (matchingSynthesis?.synthese !== 0 && item.synthese === 0) {
      item.synthese = null;
    }
    return item;
  });
};

function getMaxDepth(groupedElements, category) {
  let maxDepth = 0;
  groupedElements.forEach((range) => {
    let currentDepth = 0;
    range.forEach((month) => {
      const monthData = category?.[month];
      if (monthData) {
        currentDepth = currentDepth + monthData.length;
      }
    });

    if (currentDepth > maxDepth) {
      maxDepth = currentDepth;
    }
  });

  return maxDepth;
}

function getElementsPerRange(groupedElements, categories) {
  const elementsPerRange = {};

  groupedElements.forEach((range) => {
    const elements = [];
    range.forEach((month) => {
      const monthData = categories[month];
      if (monthData) {
        elements.push(...monthData);
      }
    });

    if (elements.length > 0) {
      const rangeKey = range.join(", ");
      elementsPerRange[rangeKey] = elements;
    }
  });

  return elementsPerRange;
}

function sortByAmount(objToSort, sortType) {
  const sortedObj = {};

  for (const key in objToSort) {
    if (Object.hasOwn(objToSort, key)) {
      const innerArray = objToSort[key];

      const sortedArray = innerArray.slice().sort((a, b) => {
        if (sortType === "asc") {
          if (a.amount === b.amount) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          } else {
            return a.amount - b.amount;
          }
        } else if (sortType === "desc") {
          if (a.amount === b.amount) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          } else {
            return b.amount - a.amount;
          }
        }
      });

      sortedObj[key] = sortedArray;
    }
  }

  return sortedObj;
}

function getElementsPerRangeElements(ranges, dataObject, sortValue) {
  const result = {};
  ranges.forEach((range) => {
    const elements = [];
    range.forEach((month) => {
      const monthData = dataObject[month];
      if (monthData) {
        elements.push(...monthData);
      }
    });

    if (elements.length > 0) {
      const rangeKey = range.join(", ");
      result[rangeKey] = elements;
    }
  });

  return sortByAmount(result, sortValue);
}

function createArrayOfObjects(elementsPerRange) {
  const ranges = Object.values(elementsPerRange);
  const maxLength = Math.max(...ranges.map((arr) => arr.length));

  const resultArray = [];

  for (let i = 0; i < maxLength; i++) {
    const objectWithElements = {};

    Object.entries(elementsPerRange).forEach(([range, elements]) => {
      if (elements[i] !== undefined) {
        objectWithElements[range] = elements[i];
      }
    });

    resultArray.push(objectWithElements);
  }

  return resultArray;
}

const calculateFooterSum = (currentElement, mode) => {
  const initialValue = {
    positiveBalance: 0,
    negativeBalance: 0,
    finalBalance: 0,
  };

  const totals = currentElement.reduce((accumulator, element) => {
    accumulator.positiveBalance += parseFloat(element.positiveBalance);
    accumulator.negativeBalance += parseFloat(element.negativeBalance);

    if (mode === "compta") {
      accumulator.finalBalance += parseFloat(element.finalBalance);
    } else {
      accumulator.finalBalance = parseFloat(element.finalBalance);
    }

    return accumulator;
  }, initialValue);

  return totals;
};

function findIdsAtLevels(data, targetId) {
  const result = [];

  function helper(nodes, level, found) {
    if (!result[level]) {
      result[level] = [];
    }

    nodes.forEach((node) => {
      result[level].push(node.id);
      if (node.id === targetId) {
        found = true;
      }
      if (node.children && node.children.length > 0) {
        helper(node.children, level + 1, found);
      }
    });

    return found;
  }

  // Start the recursion
  helper(data, 0, false);

  return result;
}

export {
  addIdKeyToCategories,
  calculateBalanceSums,
  calculateFooterSum,
  calculateMonthTotals,
  createArrayOfObjects,
  findById,
  findIdsAtLevels,
  findMaxDepth,
  findNextItemSameLevel,
  generateClassName,
  generateClassNameCompta,
  generateEmptyRow,
  getElementsPerRange,
  getElementsPerRangeElements,
  getMaxDepth,
  truncateWithThreeDots,
  updateSynthesis,
};
