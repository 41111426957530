import { DateTime } from "luxon";

export function formatDateTimeAccordingToTimeZone(dateObject, format) {

  const currentLocation = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const luxonDate = DateTime.fromJSDate(dateObject?.toDate(), {
    zone: currentLocation,
  });

  return luxonDate.toFormat(format);
}
