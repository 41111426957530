import { Input, message } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  DUPLICATE_PERIOD_COMPTA,
  DUPLICATE_PERIOD_TRESO,
  DUPLICATION_TYPE,
  ONLY_SIMUL,
  TRESORY_STATUS,
  WRITING_TYPES,
} from "../../../data/constants";
import { duplicateOneElement } from "../../../data/slices/elements";
import { closeModal } from "../../../data/slices/modals";
import { useFiltersAndDates } from "../../../hooks/useFiltersAndDate";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { daysList } from "../../../utilities/daysList";
import { getFieldClasses } from "../../../utilities/getFieldClasses";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import Button from "../../Button";
import CustomDateSelect from "../../CustomDateSelect";
import CustomSelect from "../../CustomSelect";
import CustomInput from "../../Input";
import {
  generatePlaceholder,
  handleChangeComptaDate,
  handleChangeTresoDate,
  handleInvalidInput,
} from "../../../utilities/duplication";

const DuplicateWritingForm = ({
  projectId,
  onCancel,
  element,
  handleInputFocus,
}) => {
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const location = useLocation();

  const [submitButtonState, setSubmitButtonState] = useState(null);
  const [isSpecial, isSpecialState] = useState();

  const { mode } = useSelector((state) => state.layout);
  const { reellePopup } = useSelector((state) => state.elements);

  const existingDateTreso = element?.dateTreso;
  const existingDateCompta = element?.dateCompta;

  const TRESO_MODE = mode === "treso";
  const COMPTA_MODE = mode === "compta";

  const [selectedDateTreso, setSelectedDateTreso] = useState(existingDateTreso);
  const [selectedDateCompta, setSelectedDateCompta] =
    useState(existingDateCompta);

  const [displayValueTreso, setDisplayValueTreso] = useState(null);
  const [displayValueCompta, setDisplayValueCompta] = useState(null);

  const { constructFilters, getStartAndEndDate, getStartAndEndDateSynthesis } =
    useFiltersAndDates();

  const { startDate, endDate } = getStartAndEndDate();
  const { tresoPlaceholder, comptaPlaceholder } = generatePlaceholder(
    TRESO_MODE,
    COMPTA_MODE
  );
  const { footerConstructedFilters, tableConstructedFilters } =
    constructFilters();

  const { startDateSynthesis, endDateSynthesis } =
    getStartAndEndDateSynthesis();

  const currentCategory = reellePopup?.category
    ? reellePopup?.category
    : reellePopup;

  const DuplicateWritingSchema = Yup.object().shape({
    repeated: Yup.string()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .test({
        message: "Le nombre de répétitions doit être compris entre 1 et 100",
        test: (value) => {
          const numericValue = parseInt(value, 10);
          return numericValue >= 1 && numericValue <= 100;
        },
      })
      .required("Nombre de répétitions est obligatoire"),
    duplicationType: Yup.string().required(
      "Type de duplication est obligatoire"
    ),
    dateCompta:
      (COMPTA_MODE && isSpecial) || !isSpecial
        ? Yup.string().required("Vous devez saisir une date de comptabilité !")
        : Yup.string().nullable().optional(),
    dateTreso:
      (TRESO_MODE && isSpecial) || !isSpecial
        ? Yup.string().required("Vous devez saisir une date de trésorerie ! ")
        : Yup.string().nullable().optional(),
  });

  const status = useMemo(() => {
    const isSpecial = currentCategory?.isSpecial;
    let status = null;
    if (element?.status === "pointé") {
      status = "prévisionnel";
    } else if (element?.status) {
      status = element?.status;
    } else if (COMPTA_MODE && isSpecial) {
      status = "pointé";
    } else {
      status = "prévisionnel";
    }

    return status;
  }, [element?.status, mode, currentCategory?.isSpecial]);

  const optionsTreso = TRESO_MODE
    ? daysList(existingDateTreso)
    : DUPLICATE_PERIOD_COMPTA;
  const optionsCompta = TRESO_MODE
    ? DUPLICATE_PERIOD_TRESO
    : daysList(existingDateCompta);

  const initialValues = {
    isSpecial: currentCategory?.isSpecial || false,
    dateCompta: null,
    dateTreso: null,
    type: element?.type ? element?.type : "réel",
    status: status,
    repeated: null,
    duplicationType: "",
  };

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit);
  };

  const onSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    try {
      const result = await dispatch(
        duplicateOneElement({
          elementId: element.id,
          values: {
            ...values,
            dateCompta: values?.dateCompta,
            dateTreso: values?.dateTreso,
            duplicationType: values?.duplicationType,
            repeated: values?.repeated,
          },
          project: {
            mode,
            projectId,
            params: { startDate, endDate, ...tableConstructedFilters },
          },
          isListing: location.pathname.includes("listing"),
          listing: {
            params: { ...tableConstructedFilters, startDate, endDate },
            mode,
            projectId,
          },
          sum: {
            mode,
            projectId: projectId,
            params: { startDate, endDate, ...footerConstructedFilters },
          },
          synthesis: {
            mode,
            projectId,
            params: {
              startDate: startDateSynthesis,
              endDate: endDateSynthesis,
              ...tableConstructedFilters,
            },
          },
        })
      );
      setSubmitting(false);
      if (duplicateOneElement.rejected.match(result)) {
        if (isMounted.current) {
          setSubmitting(false);
        }
        displayValidationErrors(
          result?.errors ? result.errors : result.payload
        );
      }
      if (duplicateOneElement.fulfilled.match(result)) {
        message.success("Écriture dupliquée avec succès");
        if (submitButtonState === "validate") {
          dispatch(closeModal("duplicate-writing-modal"));
        }
        resetForm();
      }
    } catch (error) {
      setSubmitting(false);
      displayValidationErrors(error);
    }
  };

  return (
    <>
      <p className="description">* champs obligatoires.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={DuplicateWritingSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          form,
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
          errors,
          touched,
        }) => {
          const statusesArray =
            values.type === "simulation" ? ONLY_SIMUL : TRESORY_STATUS;
          isSpecialState(values?.isSpecial);

          return (
            <Form
              className="form-container"
              autoComplete="off"
              preserve={false}
            >
              <div className="element-wrapper">
                <div className="custom-input">
                  <label htmlFor={"libellé"}>{"libellé"}</label>
                  <div className="input-wrapper">
                    <Input
                      label="libellé"
                      type="string"
                      style={{
                        paddingLeft: "20px",
                      }}
                      value={element?.designation}
                      className={`${getFieldClasses(
                        element.designation
                      )} disabled-input`}
                      disabled
                    />
                  </div>
                </div>
                <div className="custom-input">
                  <label htmlFor={"montant"}>{"montant"}</label>
                  <div className="input-wrapper">
                    <Input
                      label="montant"
                      type="number"
                      style={{
                        paddingLeft: "20px",
                      }}
                      value={element?.amount}
                      className={`${getFieldClasses(
                        element.amount
                      )} disabled-input`}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <CustomSelect
                name="duplicationType"
                label="Dupliquer*"
                value={values?.duplicationType}
                onChange={setFieldValue}
                onBlur={handleBlur}
                options={DUPLICATION_TYPE}
                condition
                errors={errors}
                touched={touched}
                placeholder={"choisir la récurrence"}
                style={{ width: "48%" }}
              />

              <div className="repeated-field-wrapper">
                <Field
                  component={CustomInput}
                  value={values.repeated}
                  label="Répeter*"
                  name="repeated"
                  min={1}
                  max={100}
                  type="number"
                  className={getFieldClasses(values.repeated)}
                  placeholder="saisir un nombre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onFocus={() => handleInputFocus("repeated")}
                  onInvalid={(e) => handleInvalidInput(e)}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                />
                <div className="repeated-desc">
                  fois après l’écriture d’origine
                  <br></br>
                  (maximum = 100 fois)
                </div>
              </div>
              <div className="element-wrapper">
                <CustomDateSelect
                  errors={errors}
                  touched={touched}
                  name="dateTreso"
                  placeholder={tresoPlaceholder}
                  value={values?.dateTreso}
                  options={optionsTreso}
                  displayValue={
                    displayValueTreso !== null
                      ? displayValueTreso
                      : values?.dateTreso
                  }
                  onChange={(changedValue) => {
                    handleChangeTresoDate(
                      changedValue,
                      setFieldValue,
                      setDisplayValueTreso,
                      TRESO_MODE,
                      COMPTA_MODE,
                      selectedDateTreso,
                      setSelectedDateTreso,
                      displayValueCompta,
                      setSelectedDateCompta,
                      selectedDateCompta
                    );
                  }}
                  onBlur={handleBlur}
                  onFocus={() => handleInputFocus("dateTreso")}
                  label="Date de trésorerie*"
                  condition={TRESO_MODE}
                  specialCategory={isSpecial}
                />

                <CustomDateSelect
                  errors={errors}
                  touched={touched}
                  name="dateCompta"
                  value={values.dateCompta}
                  options={optionsCompta || values?.dateCompta}
                  displayValue={displayValueCompta}
                  placeholder={comptaPlaceholder}
                  setFieldValue={setFieldValue}
                  onChange={(changedValue) =>
                    handleChangeComptaDate(
                      changedValue,
                      setFieldValue,
                      displayValueTreso,
                      setDisplayValueCompta,
                      TRESO_MODE,
                      COMPTA_MODE,
                      selectedDateTreso,
                      setSelectedDateCompta,
                      selectedDateCompta,
                      setSelectedDateTreso
                    )
                  }
                  onBlur={handleBlur}
                  onFocus={() => handleInputFocus("dateCompta")}
                  label="Date de comptabilité*"
                  condition={COMPTA_MODE}
                  specialCategory={isSpecial}
                />
              </div>

              <div className="element-wrapper">
                <CustomSelect
                  label="Statut de trésorerie*"
                  name="status"
                  onChange={setFieldValue}
                  options={statusesArray}
                  errors={errors}
                  touched={touched}
                  disabledOption="pointé"
                  form={form}
                  showIcon
                  value={
                    values.type === "simulation" ? "simulation" : values?.status
                  }
                  disabled={
                    (COMPTA_MODE && isSpecial) ||
                    (TRESO_MODE && values.type === "simulation")
                  }
                  condition={(TRESO_MODE && isSpecial) || !isSpecial}
                />
                <CustomSelect
                  label="Type d'écriture*"
                  name="type"
                  value={values?.type}
                  onChange={setFieldValue}
                  options={WRITING_TYPES}
                  disabledOption="soldé"
                  errors={errors}
                  touched={touched}
                  condition={(COMPTA_MODE && isSpecial) || !isSpecial}
                  showIcon
                />
              </div>
              <div className="submit-button-wrapper">
                <Button
                  text={isSubmitting ? "envoi en cours" : "Valider"}
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="submit-button"
                  allWidth
                  onClick={() => handleSubmitButtonState("validate")}
                />
                <Button
                  text="Annuler"
                  color="primary"
                  className="reset-button"
                  allWidth
                  onClick={(e) => {
                    onCancel();
                    e.preventDefault();
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default DuplicateWritingForm;
