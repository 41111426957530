import React from "react";
import Logo from "../../../assets/img/logo.png";

const HeaderOverlay = ({
  marginHeader,
  children,
  className,
  showlogo,
  isDelete,
}) => {
  return (
    <div className={`project-container ${className}`}>
      <div
        className={`new-project-big-container${
          isDelete ? " delete-project" : ""
        }`}
      >
        <div className={`project-header `} style={{ marginLeft: marginHeader }}>
          {!showlogo && <img src={Logo} />}
        </div>
        {children}
      </div>
    </div>
  );
};

export default HeaderOverlay;
