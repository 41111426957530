import { Link } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowDimensions";
import LogoIcon from "../../assets/img/logo.png";
import logo_respensive from "../../assets/img/logo-resp.svg";
import logoSvg from "../../assets/img/logoSvg.svg";

const Logo = ({ isSidebarClosed, isLogin }) => {
  const isSmallScreen = useWindowSize() <= 900;
  const handelogoToShow = () => {
    let logoToShow;
    if (isLogin) {
      logoToShow = logoSvg;
    } else if (isSmallScreen || isSidebarClosed) {
      logoToShow = logo_respensive;
    } else {
      logoToShow = LogoIcon;
    }
    return logoToShow;
  };

  return (
    <Link className={isLogin ? "logo-login" : "logo"}>
      <img alt="logo" src={handelogoToShow()} />
    </Link>
  );
};

export default Logo;
