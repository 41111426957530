import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOneProject } from "../../../data/slices/project";
import projectIcon from "./../../../assets/img/project.svg";
import useAuth from "../../../hooks/useAuth";
import user from "../../../assets/img/icons/user.svg";
import { PATH_HOME } from "../../../routes/paths";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import { truncateWithThreeDots } from "../../../utilities/table";
import { Tooltip } from "antd";

const Settings = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project } = useSelector((state) => state.project);
  const { isSidebarClosed } = useSelector((state) => state.layout);

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      logout();
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    dispatch(getOneProject(id)).then((result) => {
      if (result.error) {
        displayValidationErrors(result?.error);
        navigate(PATH_HOME.project.root);
      }
    });
  }, [id]);

  return (
    <>
      <Tooltip
        placement={"top"}
        mouseLeaveDelay={0}
        title={
          project?.projectName && project?.projectName.length > 20
            ? project?.projectName
            : isSidebarClosed
            ? "Mes dossiers"
            : null
        }
      >
        <button
          key={project?.id}
          type="button"
          onClick={() => {
            navigate(PATH_HOME.project.root);
          }}
          className="button nav-link config-buttons"
        >
          <img className="nav-img" src={projectIcon} />
          <span className="hidden-element">
            {truncateWithThreeDots(project?.projectName, 20)}
          </span>
        </button>
      </Tooltip>
      <Tooltip
        placement={"top"}
        trigger="hover"
        title={!isSidebarClosed ? "" : "Déconnecter"}
        mouseLeaveDelay={0}
        // visible={!isSidebarClosed}
      >
        <button
          key="logout"
          type="button"
          onClick={handleLogout}
          className="button nav-link config-buttons"
        >
          <img className="nav-img" src={user} />
          <span className="hidden-element log-out-element ">Déconnecter</span>
        </button>
      </Tooltip>
    </>
  );
};

export default Settings;
