import React from "react";
import Button from "../../Button";
import Title from "../../Project/Title";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";

function VerifyClosing({ open,data }) {
  const handleCloseFunc = () => {
    data.onClose();
  };

  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            "Lorsque vous saisissez directement dans les cellules du tableau, vous devez valider votre saisie par la touche entrée."
          }
          subTitle={"Vous n'avez pas terminé votre saisie!"}
        />
        <div className="verify-inputs-popup">
          <Button
            className={"button-verify-inputs"}
            text={"Abandonner"}
            onClick={() => data.abandon()}
          />

          <Button
            text={"Reprendre la saisie "}
            onClick={() => handleCloseFunc()}
            className={
              "action-button  agree-action-button button-verify-inputs"
            }
            iconClassName={"delete-icon"}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default VerifyClosing;
