import { message } from "antd";

const displayValidationErrors = (error) => {
  if (error.errors) {
    for (const key in error.errors) {
      if (error.errors.hasOwnProperty(key)) {
        const errorMessage = error.errors[key];
        message.error(errorMessage);
      }
    }
  } else {
    message.error(error.message || "une erreur est survenue");
  }
};

export { displayValidationErrors };
