function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_FOLDER = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_HOME = {
  folder: {
    root: (id) => `/${id}`,
  },
  project: {
    root: "/project",
    new: "/project/new",
    view: (id) => `/project/${id}`,
    edit: (id) => `/project/${id}/edit`,
    delete: (id) => `/project/${id}/delete`,
  },
  curve: {
    root: path(ROOTS_FOLDER, "graphic"),
    graph: (id) => `/project/${id}/graph`,
    listing: (id) => `/project/${id}/listing`,
  },
};
