import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname === "/verify-email") {
        return Promise.reject(
          (error.response?.data) || "une erreur est survenue"
        );
      }
    } else {
      return Promise.reject(
        (error.response?.data) || "une erreur est survenue"
      );
    }
  }
);

export default axiosInstance;
