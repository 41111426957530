function groupMonths(number, arr) {
  const result = [];
  for (let i = 0; i < arr.length; i += number) {
    result.push(arr.slice(i, i + number));
  }
  return result;
}

function getColumnNameFromMonths(monthsArray, regroupmentValue) {
  if (!Array.isArray(monthsArray) || monthsArray.length === 0) {
    return "";
  }

  if (monthsArray.length === 1) {
    return monthsArray[0];
  }

  const firstMonthParts = monthsArray[0].split(" ");
  const firstYear = firstMonthParts[1].slice(-4);

  const monthNames = {
    janvier: "janv",
    février: "févr",
    mars: "mars",
    avril: "avr",
    mai: "mai",
    juin: "juin",
    juillet: "juil",
    août: "août",
    septembre: "sept",
    octobre: "oct",
    novembre: "nov",
    décembre: "déc",
  };

  const lastMonthParts = monthsArray[monthsArray.length - 1].split(" ");
  const firstMonthAbbr =
    monthNames[firstMonthParts[0].toLowerCase()] ||
    firstMonthParts[0].substring(0, 3);
  const lastMonthAbbr =
    monthNames[lastMonthParts[0].toLowerCase()] ||
    lastMonthParts[0].substring(0, 3);
  const lastYear = lastMonthParts[1].slice(-4);

  if (firstYear !== lastYear) {
    return `${firstMonthAbbr}.${firstYear}-${lastMonthAbbr}.${lastYear}`;
  }

  return `${firstMonthAbbr}.${firstYear}-${lastMonthAbbr}.${lastYear}`;
}

export { groupMonths, getColumnNameFromMonths };
