import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [screenWidth, setWindowScreenWidth] = useState();

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const screenWidth = window.innerWidth;
    setWindowScreenWidth(screenWidth);
  };

  return screenWidth;
};
