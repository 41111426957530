import { convertToYYYYMMDD, formatDateString } from "./filters";

export function getDispalyedValueCompta({
  displayValueCompta,
  TRESO_MODE,
  values,
  getLastDayLabel,
  matchingLabelCompta,
}) {
  let displayValue = null;
  if (displayValueCompta !== null) {
    displayValue = displayValueCompta;
  } else if (TRESO_MODE) {
    displayValue = matchingLabelCompta;
  } else {
    displayValue = getLastDayLabel(values?.dateCompta);
  }
  return displayValue;
}
export function getDisplayValueTreso(
  displayValueTreso,
  TRESO_MODE,
  values,
  getLastDayLabel,
  matchingLabelTreso
) {
  let displayValue = null;

  if (displayValueTreso !== null) {
    displayValue = displayValueTreso;
  } else if (TRESO_MODE) {
    displayValue = getLastDayLabel(values?.dateTreso);
  } else {
    displayValue = matchingLabelTreso;
  }

  return displayValue;
}
export function getStatus(edit, element, COMPTA_MODE, isSpecial) {
  if (edit) {
    return element?.status;
  } else if (COMPTA_MODE && isSpecial) {
    return "pointé";
  } else {
    return "prévisionnel";
  }
}
export function getInitialValueDate(
  mode,
  edit,
  existingDate,
  date,
  sameMonthAndYear,
  isAction
) {

  if (edit && existingDate !== null) {
    return formatDateString(existingDate);
  } else if (mode) {
    if (date && !sameMonthAndYear) {
      return formatDateString(convertToYYYYMMDD(date));
    } else {
      return !isAction ? formatDateString(new Date()) : null;
    }
  } else {
    return null;
  }
}
