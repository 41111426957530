import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";

const initialState = {
  synthese: [],
  mode: "",
  checkedElementCompta: {},
  checkedElementTreso: {},
  filtersComptaValues: { comptaStatus: null, comptaTypes: "réel" },
  filtersTresoValues: { tresoStatus: "pointé", tresoTypes: undefined },
  status: "idle",
  error: null,
};
export const getSynthese = createAsyncThunk(
  "synthese/getSynthese",
  async (data, thunkAPI) => {
    const { projectId, params } = data;
    let responseData;

    try {
      const response = await axios.get(
        `/api/synthese/graphs/monthly/${projectId}`,
        { params }
      );

      responseData = await response.data;

      if (response.status === 200) {
        return responseData?.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: "synthese",
  initialState,
  reducers: {
    setFiltersTréso: (state, action) => {
      state.filtersTresoValues = action.payload;
    },
    setFiltersCompta: (state, action) => {
      state.filtersComptaValues = action.payload;
    },
    setModeChart: (state, action) => {
      state.mode = action.payload;
    },
    getCheckedElementsCompta: (state, action) => {
      state.checkedElementCompta = action.payload;
    },
    getCheckedElementsTreso: (state, action) => {
      state.checkedElementTreso = action.payload;
    },
  },
  extraReducers: {
    [getSynthese.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSynthese.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.synthese = action.payload;
    },
    [getSynthese.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {
  getCheckedElementsTreso,
  getCheckedElementsCompta,
  setFilters,
  setModeChart,
  setFiltersTréso,
  setFiltersCompta,
} = slice.actions;
export default slice;
