import { Select } from "antd";

const CustomDateSelect = ({
  name,
  value,
  options,
  onChange,
  onBlur,
  onFocus,
  label,
  condition,
  specialCategory,
  placeholder,
  errors,
  touched,
  displayValue,
  style,
}) => {
  const error = errors[name];
  const touch = touched[name];
  
  const handelValue = () => {
    let value;
    if (displayValue === 0) {
      value = 0;
    } else if (displayValue) {
      value = displayValue;
    } else {
      value = null;
    }
    return value;
  };

  return (
    <>
      {((condition && specialCategory) || !specialCategory) && (
        <div className="wrapper-select" style={style}>
          {label && (
            <label htmlFor={name} className="input-text">
              {label}
            </label>
          )}
          <Select
            name={name}
            value={handelValue()}
            bordered={false}
            placeholder={placeholder}
            className={
              value
                ? "form-element-input small"
                : "form-element-input calendar not-filled"
            }
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          >
            {options.map((option) => (
              <Select.Option
                key={option.key}
                value={option.value}
                className="option"
              >
                <div className="img-container element-status">
                  <div>{option.label}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
          {error && touch ? (
            <div className="error-displayer-form-modal">{error}</div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default CustomDateSelect;
