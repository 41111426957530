const formattedCategories = (categories) => {
  return categories.map((categroy) => {
    const { id, name, children } = categroy;
    const treeCategories = {
      value: id,
      title: name,
    };
    if (children && children.length > 0) {
      treeCategories.children = formattedCategories(children);
    }

    return treeCategories;
  });
};

export default formattedCategories;
