import React from "react";

const MenuButton = ({
  id,
  handleSubmit,
  name,
  className,
  primaryIcon,
  secondaryIcon,
  onHoverIn,
  onHoveringOut,
}) => {
  return (
    <button
      key={id}
      type="button"
      onClick={handleSubmit}
      className={className}
      onMouseOver={onHoverIn}
      onMouseOut={onHoveringOut}
    >
      <img className="nav-img" src={primaryIcon} />
      <span className="hidden-element">{name}</span>
      <img className="nav-img" src={secondaryIcon} id="arrow" />
    </button>
  );
};
export default MenuButton;
