import { Suspense, lazy } from "react";
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

//auth
export const Login = Loadable(lazy(() => import("../pages/Auth/Login")));
export const Register = Loadable(lazy(() => import("../pages/Auth/Register")));

export const ForgetPassword = Loadable(
  lazy(() => import("../pages/Auth/ForgetPassword"))
);
export const ResendCode = Loadable(
  lazy(() => import("../pages/Auth/ResendCode"))
);
export const VerifyAccount = Loadable(
  lazy(() => import("../pages/Auth/VerifyAccount"))
);

export const ResetPassword = Loadable(
  lazy(() => import("../pages/Auth/ResetPassword"))
);

export const FolderList = Loadable(lazy(() => import("../pages/Folder")));
export const ProjectCreate = Loadable(
  lazy(() => import("../pages/Project/ProjectCreate"))
);
export const ProjectEdit = Loadable(
  lazy(() => import("../pages/Project/ProjectEdit"))
);
export const ProjectDelete = Loadable(
  lazy(() => import("../pages/Project/ProjectDelete"))
);
export const ProjectList = Loadable(
  lazy(() => import("../pages/Project/ProjectList"))
);

export const Graph = Loadable(lazy(() => import("../pages/Graph")));

export const NotFound = Loadable(
  lazy(() => import("../pages/Auth/NotFound/NotFound"))
);
