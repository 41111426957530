export const daysList = (elementDate) => {
  const options = [];

  const month = new Date(elementDate).getMonth() + 1;
  const year = new Date(elementDate).getFullYear();

  const daysInMonth = new Date(year, month, 0).getDate();

  for (let i = 1; i <= daysInMonth - 1; i++) {
    options.push({ value: i, label: i });
  }

  options.push({ value: daysInMonth, label: "Dernier jour du mois" });

  return options;
};
