import React from "react";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, isVerified } =
    useAuth();

  if (!isVerified && isAuthenticated) {
    return (window.location = "/verify-email");
  }

  if (isAuthenticated) {
    return <Navigate to={"/project"}></Navigate>;
  }

  return <>{children}</>;
};

export default GuestGuard;
