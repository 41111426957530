import dayjs from "dayjs";
import {
  DUPLICATE_PERIOD_COMPTA,
  DUPLICATE_PERIOD_TRESO,
} from "../data/constants";
import { formatDateString } from "./filters";

function getLabelForDateDifference(mode, dateTreso, dateCompta) {
  const dateTresoObj = dayjs(dateTreso);
  const dateComptaObj = dayjs(dateCompta);

  const monthDifference = dateTresoObj.diff(dateComptaObj, "month");
  let options =
    mode === "compta" ? DUPLICATE_PERIOD_COMPTA : DUPLICATE_PERIOD_TRESO;

  const matchingLabel = options.find(
    (option) => option.value === Math.abs(monthDifference)
  );

  return {
    monthDifference: monthDifference,
    matchingLabel: matchingLabel?.label ? matchingLabel?.label : "",
  };
}

function getLastDayLabel(date) {
  const dateObj = new Date(date);

  const lastDayOfMonth = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth() + 1,
    0
  ).getDate();

  if (dateObj.getDate() === lastDayOfMonth) {
    return "Dernier jour du mois";
  } else {
    return dayjs(date).format("D");
  }
}

function subtractMonth(date, selectedDate) {
  return dayjs(date)?.subtract(selectedDate, "month")?.toISOString();
}

function addMonth(date, selectedDate) {
  return dayjs(date).add(selectedDate, "month")?.toISOString();
}

function getDate(date, selectedDate) {
  return dayjs(date).date(selectedDate)?.toISOString();
}

const handleChangeComptaDate = (
  changedValue,
  setFieldValue,
  displayValueTreso,
  setDisplayValueCompta,
  TRESO_MODE,
  COMPTA_MODE,
  selectedDateTreso,
  setSelectedDateCompta,
  selectedDateCompta,
  setSelectedDateTreso,
  specialCategory,
  edit,
  tresoValue
) => {
  setDisplayValueCompta(changedValue);

  let updatedDate;
  if (TRESO_MODE) {
    updatedDate = subtractMonth(selectedDateTreso, changedValue);
    setSelectedDateCompta(updatedDate);
    if (edit) {
      setFieldValue("decalageDate", (-parseFloat(changedValue)).toString());
    } else {
      setFieldValue("dateCompta", formatDateString(updatedDate));
    }
  } else if (COMPTA_MODE) {
    updatedDate = getDate(selectedDateCompta, changedValue);
    setSelectedDateCompta(updatedDate);
    if (displayValueTreso !== null ) {
      let updatedTresoValue = addMonth(updatedDate, displayValueTreso);
      setSelectedDateTreso(updatedTresoValue);
      if (edit) {
        if (tresoValue !== null) {
          setFieldValue("decalageDate", tresoValue.toString());
          setFieldValue("decalageDay", formatDateString(updatedDate));
        } else {
          setFieldValue("decalageDay", formatDateString(updatedDate));
        }
      } else {
        setFieldValue("dateTreso", formatDateString(updatedTresoValue));
        setFieldValue("dateCompta", formatDateString(updatedDate));
      }
    }
    !edit && setFieldValue("dateCompta", formatDateString(updatedDate));
  }
};

const handleChangeTresoDate = (
  changedValue,
  setFieldValue,
  setDisplayValueTreso,
  TRESO_MODE,
  COMPTA_MODE,
  selectedDateTreso,
  setSelectedDateTreso,
  monthDifferenceCompta,
  setSelectedDateCompta,
  selectedDateCompta,
  specialCategory,
  edit,
  displayValueCompta
) => {
  let updatedDate;
  setDisplayValueTreso(changedValue);
  if (TRESO_MODE) {
    updatedDate = getDate(selectedDateTreso, changedValue);
    setSelectedDateTreso(updatedDate);

    if (monthDifferenceCompta !== null ) {
      let updatedComptaValue = subtractMonth(
        updatedDate,
        monthDifferenceCompta
      );
      if (edit) {
        if (displayValueCompta) {
          setFieldValue(
            "decalageDate",
            (-parseFloat(displayValueCompta)).toString()
          );
          setFieldValue("decalageDay", formatDateString(updatedDate));
        } else {
          setFieldValue("decalageDay", formatDateString(updatedDate));
        }
      } else {
        setFieldValue("dateCompta", formatDateString(updatedComptaValue));
      }
    }

    !edit && setFieldValue("dateTreso", formatDateString(updatedDate));
  } else if (COMPTA_MODE) {
    updatedDate = addMonth(selectedDateCompta, changedValue);
    setSelectedDateTreso(updatedDate);
    if (edit) {
      setFieldValue("decalageDate", changedValue.toString());
    }
    !edit && setFieldValue("dateTreso", formatDateString(updatedDate));
  }
};

const generatePlaceholder = (TRESO_MODE, COMPTA_MODE) => {
  const decalage = "choisir le décalage";

  const tresoPlaceholder = TRESO_MODE ? "jour du mois de trésorerie" : decalage;
  const comptaPlaceholder = COMPTA_MODE
    ? "jour du mois de comptabilité"
    : decalage;

  return { tresoPlaceholder, comptaPlaceholder };
};

const handleInvalidInput = (e) => {
  if (e.target.validity.rangeUnderflow) {
    e.target.setCustomValidity("La valeur doit être supérieure ou égale à  1.");
  } else if (e.target.validity.rangeOverflow) {
    e.target.setCustomValidity(
      "La valeur doit être inférieure ou égale à  100."
    );
  }
};

export {
  getLabelForDateDifference,
  getLastDayLabel,
  subtractMonth,
  addMonth,
  getDate,
  handleChangeComptaDate,
  handleChangeTresoDate,
  generatePlaceholder,
  handleInvalidInput,
};
