import Sidebar from "../SideBar";

const DashboardLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <Sidebar />
      <div className="children_container">{children}</div>
    </div>
  );
};
export default DashboardLayout;
