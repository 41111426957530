import React from "react";

const Button = ({
  RigthIcon,
  text,
  key,
  onClick,
  icon,
  className,
  style,
  iconClassName,
  isRigthIcon,
  disabled,
  ...rest
}) => {
  return typeof icon === "string" ? (
    <button
      className={`${className} button-wrapper submitting-button`}
      onClick={onClick}
      style={{ ...style }}
      disabled={disabled}
    >
      {icon !== "empty" && (
        <img src={icon} alt="icon" className={iconClassName} />
      )}
      <span> {text}</span>
      {isRigthIcon && (
        <div className="rigth-icon-wrapper">
          <img src={RigthIcon} alt="icon" className={iconClassName} />
        </div>
      )}
    </button>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`submitting-button ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
