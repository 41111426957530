export const adjustCalendarYears = (selectedYear, isLeft) => {
  requestAnimationFrame(() => {
    const calendarCells = document
      .querySelector(`.ant-picker-dropdown:not(.ant-picker-dropdown-hidden)`)
      .querySelectorAll(".ant-picker-content");
    const nextButton = document
      .querySelector(`.ant-picker-dropdown:not(.ant-picker-dropdown-hidden)`)
      .querySelector(".ant-picker-header-super-next-btn");
    const prevButton = document
      .querySelector(`.ant-picker-dropdown:not(.ant-picker-dropdown-hidden)`)
      .querySelector(".ant-picker-header-super-prev-btn");

    const [leftYear, rightYear] = [...calendarCells].map((cell) =>
      Number(cell.querySelector("td[title]").getAttribute("title").slice(0, 4))
    );

    const yearDifference = selectedYear - (isLeft ? leftYear : rightYear);

    if (yearDifference === 0) return;

    if (yearDifference > 0) {
      for (let i = 0; i < yearDifference; i++) {
        requestAnimationFrame(() => nextButton.click());
      }
    }

    if (yearDifference < 0) {
      for (let i = 0; i < -yearDifference; i++) {
        requestAnimationFrame(() => prevButton.click());
      }
    }
  });
};
