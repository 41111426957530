import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {DuplicateWritingModal, AddWritingModal, VerifyClosing } from "../Modals";

import { closeModal } from "../../data/slices/modals";

const ModalsProvider = () => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };

  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  return (
    <>
      <AddWritingModal
        id="add-writing-expression"
        open={modalState("add-writing-expression", "open")}
        data={modalState("add-writing-expression", "data")}
        handleClose={handleClose}
      />

      <VerifyClosing
        id="verify-closing-modal"
        open={modalState("verify-closing-modal", "open")}
        data={modalState("verify-closing-modal", "data")} />
      
      <DuplicateWritingModal
        id="duplicate-writing-modal"
        open={modalState("duplicate-writing-modal", "open")}
        data={modalState("duplicate-writing-modal", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
