import { TreeSelect, message } from "antd";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  DECALAGE_SERIE,
  DUPLICATE_PERIOD_COMPTA,
  DUPLICATE_PERIOD_TRESO,
  ONLY_SIMUL,
  TRESORY_STATUS,
  WRITING_TYPES,
} from "../../../data/constants";
import { getCategories } from "../../../data/slices/categories";
import {
  createElements,
  editElement,
  editElementSerie,
} from "../../../data/slices/elements";
import { closeModal } from "../../../data/slices/modals";
import { useFiltersAndDates } from "../../../hooks/useFiltersAndDate";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { daysList } from "../../../utilities/daysList";
import {
  getLabelForDateDifference,
  getLastDayLabel,
  handleChangeComptaDate,
  handleChangeTresoDate,
} from "../../../utilities/duplication";
import { convertToYYYYMMDD } from "../../../utilities/filters";
import formattedCategories from "../../../utilities/formattedCategories";
import getChangedValues from "../../../utilities/getChangedValues";
import { getFieldClasses } from "../../../utilities/getFieldClasses";
import { isZero } from "../../../utilities/isZero";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import { findById } from "../../../utilities/table";
import Button from "../../Button";
import CustomDatePicker from "../../CustomDatePicker";
import CustomDateSelect from "../../CustomDateSelect";
import CustomSelect from "../../CustomSelect";
import CustomInput from "../../Input";
import {
  getDispalyedValueCompta,
  getDisplayValueTreso,
  getInitialValueDate,
  getStatus,
} from "../../../utilities/writingForm";

const AddWritingForm = ({
  onCancel,
  edit,
  element,
  selectedCategory,
  projectId,
  isAction,
  date,
  handleInputFocus,
  type,
}) => {
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const location = useLocation();
  const [submitButtonState, setSubmitButtonState] = useState(null);
  const { constructFilters, getStartAndEndDate, getStartAndEndDateSynthesis } =
    useFiltersAndDates();
  const [isSpecial, isSpecialState] = useState();
  const { footerConstructedFilters, tableConstructedFilters } =
    constructFilters();
  const { startDate, endDate } = getStartAndEndDate();
  const { startDateSynthesis, endDateSynthesis } =
    getStartAndEndDateSynthesis();

  const { mode } = useSelector((state) => state.layout);
  const { reellePopup } = useSelector((state) => state.elements);
  const { categories, categoriesForSelect } = useSelector(
    (state) => state.categories
  );

  const { expandedCategories } = useSelector((state) => state.table);

  const currentCategory = reellePopup?.category
    ? reellePopup?.category
    : reellePopup;

  let categoriesForSelectToUse;
  if (currentCategory) {
    categoriesForSelectToUse = categoriesForSelect.filter(
      (el) => currentCategory?.isSpecial === el?.isSpecial
    );
  } else if (isAction && type === "simulation") {
    categoriesForSelectToUse = categoriesForSelect.filter(
      (el) => el?.isSpecial !== 1
    );
  } else {
    categoriesForSelectToUse = categoriesForSelect;
  }

  const categoryId = currentCategory?.id;

  const finalCategories = formattedCategories(categoriesForSelectToUse);

  const TRESO_MODE = mode === "treso";
  const COMPTA_MODE = mode === "compta";

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories({ projectId: projectId, mode: mode }));
    }
  }, [projectId, mode]);

  const WritingSchema = Yup.object().shape({
    designation: Yup.string().required("Libellé à saisir"),
    amount: Yup.string().required("Montant à saisir"),
    categoryId: Yup.string().required("Catégorie à sélectionner"),
    type: Yup.string().required("Type est obligatoire"),
    status: Yup.string().required("Statut est obligatoire"),
    dateCompta:
      (COMPTA_MODE && isSpecial) || !isSpecial
        ? Yup.string().required("Date de compta à saisir")
        : Yup.string().nullable().optional(),
    dateTreso:
      (TRESO_MODE && isSpecial) || !isSpecial
        ? Yup.string().required("Date de trésorerie à saisir")
        : Yup.string().nullable().optional(),
  });

  const customDate = dayjs(convertToYYYYMMDD(date));
  const currentDate = dayjs();

  const sameMonthAndYear =
    customDate.month() === currentDate.month() &&
    customDate.year() === currentDate.year();

  const existingDateTreso = element?.dateTreso;

  const existingDateCompta = element?.dateCompta;
  const isDuplicated = !!element?.isDuplicated;

  const [selectedDateTreso, setSelectedDateTreso] = useState(existingDateTreso);

  const [selectedDateCompta, setSelectedDateCompta] =
    useState(existingDateCompta);

  const [displayValueTreso, setDisplayValueTreso] = useState(null);
  const [displayValueCompta, setDisplayValueCompta] = useState(null);
  const [displayDecalageValue, setDisplayDecalageValue] = useState(null);

  const optionsTreso = TRESO_MODE
    ? daysList(existingDateTreso)
    : DUPLICATE_PERIOD_COMPTA;

  const optionsCompta = TRESO_MODE
    ? DUPLICATE_PERIOD_TRESO
    : daysList(existingDateCompta);

  const statusInitialValue = useMemo(() => {
    const isSpecial = currentCategory?.isSpecial;
    return getStatus(edit, element, COMPTA_MODE, isSpecial);
  }, [edit, element?.status, mode, currentCategory?.isSpecial]);

  let typeInitialValue = null;
  if (type === "simulation") {
    typeInitialValue = "simulation";
  } else if (edit) {
    typeInitialValue = element.type;
  } else {
    typeInitialValue = "réel";
  }

  let comptaDateInitialValue = getInitialValueDate(
    COMPTA_MODE,
    edit,
    existingDateCompta,
    date,
    sameMonthAndYear,
    isAction
  );
  let tresoDateInitialValue = getInitialValueDate(
    TRESO_MODE,
    edit,
    existingDateTreso,
    date,
    sameMonthAndYear,
    isAction
  );
  const initialValues = {
    isSpecial: currentCategory?.isSpecial || false,
    designation: edit ? element?.designation : null,
    amount: edit ? element?.amount.toString() : null,
    categoryId: currentCategory ? currentCategory?.id : null,
    dateCompta: comptaDateInitialValue,
    dateTreso: tresoDateInitialValue,
    type: typeInitialValue,
    status: statusInitialValue,
    decalageDate: null,
  };

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit);
  };

  const handelChangeCategories = (setFieldValue, value) => {
    if (value) {
      setFieldValue("categoryId", value);
      const categoryToSend = findById(value, categoriesForSelect);
      const isSpecial = categoryToSend.isSpecial;
      setFieldValue("isSpecial", isSpecial);
      if (isAction && COMPTA_MODE && isSpecial) {
        setFieldValue("status", "pointé");
      }
    }
  };

  const onSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    try {
      if (edit) {
        const amount = values?.amount;
        let dataState = {
          designation: values.designation,
          amount: amount.toString(),
          dateCompta: values?.dateCompta,
          dateTreso: values?.dateTreso,
          type: values.type,
          status: values.status,

          ...(isDuplicated && type === "all"
            ? {
                decalageDate: values.decalageDate,
                decalageDay: values.decalageDay,
                decalageSerie: values.decalageSerie,
              }
            : {}),
          ...{ categoryId: Number(values.categoryId) },
        };
        if (selectedCategory?.id !== values.category && !isDuplicated) {
          dataState = {
            designation: values.designation,
            categoryId: Number(values.categoryId),
            amount: amount.toString(),
            dateCompta: values?.dateCompta,
            dateTreso: values?.dateTreso,
            type: values.type,
            status: values.status,
          };
        }
        const editBody = {
          elementId: element?.id,
          values: dataState,
          project: {
            mode,
            projectId: projectId,
            params: { startDate, endDate, ...tableConstructedFilters },
          },
          isListing: location.pathname.includes("listing"),
          listing: {
            params: { ...tableConstructedFilters, startDate, endDate },
            mode,
            projectId,
          },
          sum: {
            mode,
            projectId: projectId,
            params: { startDate, endDate, ...footerConstructedFilters },
          },
          synthesis: {
            mode,
            projectId,
            params: {
              startDate: startDateSynthesis,
              endDate: endDateSynthesis,
              ...tableConstructedFilters,
            },
          },
        };

        const fieldUpdated = getChangedValues(editBody.values, initialValues);

        if (isDuplicated && Object.keys(fieldUpdated).length === 0) {
          message.error(
            "Aucune modification détectée. Veuillez changer au moins un champ avant de valider."
          );
        } else {
          if (isDuplicated) {
            let result;
            if (type === "all") {
              result = await dispatch(
                editElementSerie({
                  ...editBody,
                  fieldUpdated,
                  type: "all",
                })
              );
            } else {
              result = await dispatch(
                editElementSerie({
                  ...editBody,
                  fieldUpdated,
                  type: "one",
                })
              );
            }

            if (editElementSerie.rejected.match(result)) {
              if (isMounted.current) {
                setSubmitting(false);
              }
              displayValidationErrors(
                result?.errors ? result.errors : result?.payload
              );
            }
            if (editElementSerie.fulfilled.match(result)) {
              message.success(
                `${
                  type === "all" ? "La série " : "L'écriture"
                } à été modifiée avec succès`
              );

              if (submitButtonState === "validate") {
                dispatch(closeModal("add-writing-expression"));
              }
            }
          }
        }
        if (!isDuplicated) {
          const result = await dispatch(editElement({ ...editBody }));
          setSubmitting(false);
          resetForm();

          if (editElement.rejected.match(result)) {
            if (isMounted.current) {
              setSubmitting(false);
            }
            displayValidationErrors(
              result?.errors ? result.errors : result?.payload
            );
          }
          if (editElement.fulfilled.match(result)) {
            message.success("Écriture à été modifiée avec succès");

            if (submitButtonState === "validate") {
              dispatch(closeModal("add-writing-expression"));
            }
          }
        }
      } else {
        const categoryToSend = findById(values.categoryId, categoriesForSelect);
        let datesToSend = {};
        if (categoryToSend.isSpecial === 0) {
          datesToSend = {
            dateCompta: values.dateCompta,
            dateTreso: values?.dateTreso,
          };
        } else if (COMPTA_MODE) {
          datesToSend = {
            dateCompta: values.dateCompta,
            dateTreso: null,
          };
        } else {
          datesToSend = {
            dateTreso: values?.dateTreso,
            dateCompta: null,
          };
        }

        const result = await dispatch(
          createElements({
            categoryId: values.categoryId ? values.categoryId : categoryId,
            values: {
              ...values,
              designation: values.designation,
              amount: values.amount,
              ...datesToSend,
              type: values.type,
              status: values.status,
            },
            expandedCategories,
            project: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...tableConstructedFilters },
            },
            isListing: location.pathname.includes("listing"),
            listing: {
              params: { ...tableConstructedFilters, startDate, endDate },
              mode,
              projectId,
            },
            sum: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...footerConstructedFilters },
            },
            synthesis: {
              mode,
              projectId: projectId,
              params: {
                startDate: startDateSynthesis,
                endDate: endDateSynthesis,
                ...tableConstructedFilters,
              },
            },
          })
        );
        setSubmitting(false);
        if (createElements.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result.payload
          );
        }
        if (createElements.fulfilled.match(result)) {
          message.success("Écriture à été ajoutée avec succès");
          if (submitButtonState === "validate") {
            dispatch(closeModal("add-writing-expression"));
          }
          resetForm();
        }
      }
    } catch (error) {
      setSubmitting(false);
      displayValidationErrors(error);
    }
  };

  return (
    <>
      <p className="description">* champs obligatoires.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={WritingSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
        }) => {
          const specialCategory = values.isSpecial;
          const statusesArray =
            values.type === "simulation" ? ONLY_SIMUL : TRESORY_STATUS;
          const labelDifference = getLabelForDateDifference(
            "compta",
            values.dateTreso,
            values.dateCompta
          );
          const labelDifferenceCompta = getLabelForDateDifference(
            "treso",
            values.dateTreso,
            values.dateCompta
          );

          const matchingLabelTreso = labelDifference["matchingLabel"];
          const monthDifferenceTreso = labelDifference["monthDifference"];
          const matchingLabelCompta = labelDifferenceCompta["matchingLabel"];
          const monthDifferenceCompta =
            labelDifferenceCompta["monthDifference"];
          isSpecialState(values?.isSpecial);
          return (
            <Form
              className="form-container"
              autoComplete="off"
              preserve={false}
            >
              <Field
                component={CustomInput}
                value={values.designation}
                label="Libellé*"
                name="designation"
                type="text"
                className={getFieldClasses(values.designation)}
                placeholder="Saisir un libellé"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={() => handleInputFocus("designation")}
              />
              <Field
                component={CustomInput}
                value={values.amount}
                label="Montant*"
                name="amount"
                type="string"
                className={getFieldClasses(values.amount)}
                placeholder="Saisir un montant"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={() => handleInputFocus("amount")}
              />
              <div className="wrapper-select">
                <label htmlFor="type" className="input-text">
                  Catégorie*
                </label>
                <TreeSelect
                  showSearch
                  // disabled={isDuplicated}
                  bordered={false}
                  filterTreeNode={(input, treeNode) =>
                    treeNode.title.toLowerCase().includes(input.toLowerCase())
                  }
                  name="category"
                  value={values.categoryId}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Choisir une catégorie"
                  allowClear
                  className={getFieldClasses(values.categoryId, true)}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    handelChangeCategories(setFieldValue, value);
                  }}
                  onFocus={() => handleInputFocus("category")}
                  treeData={finalCategories}
                  treeDefaultExpandedKeys={[`${values.categoryId}`]}
                />

                {errors.categoryId && touched.categoryId ? (
                  <div className="error-displayer-form-modal">
                    {errors.categoryId}
                  </div>
                ) : null}
              </div>

              {isDuplicated && type == "all" && (
                <CustomDateSelect
                  style={
                    TRESO_MODE
                      ? { width: "48%" }
                      : { width: "48%", marginLeft: "51%" }
                  }
                  label={"Décaler la série de  *"}
                  displayValue={
                    displayDecalageValue !== null
                      ? displayDecalageValue
                      : "Ne pas décaler"
                  }
                  name="decalageSerie"
                  value={TRESO_MODE ? values?.dateTreso : values?.dateCompta}
                  options={DECALAGE_SERIE}
                  specialCategory={specialCategory}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  onFocus={() => handleInputFocus("dateTreso")}
                  onChange={(changedValue) => {
                    setDisplayDecalageValue(changedValue);
                    if (isZero(changedValue)) {
                      setFieldValue("decalageSerie", undefined);
                    } else {
                      setFieldValue("decalageSerie", changedValue?.toString());
                    }
                  }}
                />
              )}
              <div className="element-wrapper">
                {isDuplicated && type == "all" ? (
                  <CustomDateSelect
                    displayValue={getDisplayValueTreso(
                      displayValueTreso,
                      TRESO_MODE,
                      values,
                      getLastDayLabel,
                      matchingLabelTreso
                    )}
                    name="dateTreso"
                    value={values?.dateTreso}
                    options={optionsTreso}
                    specialCategory={specialCategory}
                    errors={errors}
                    touched={touched}
                    onChange={(changedValue) => {
                      handleChangeTresoDate(
                        changedValue,
                        setFieldValue,
                        setDisplayValueTreso,
                        TRESO_MODE,
                        COMPTA_MODE,
                        selectedDateTreso,
                        setSelectedDateTreso,
                        monthDifferenceCompta,
                        setSelectedDateCompta,
                        selectedDateCompta,
                        specialCategory,
                        edit,
                        displayValueCompta
                      );
                    }}
                    onBlur={handleBlur}
                    onFocus={() => handleInputFocus("dateTreso")}
                    label={
                      TRESO_MODE
                        ? "Jour du mois de trésorerie*"
                        : "Date de trésorerie (décalage)*"
                    }
                    condition={TRESO_MODE}
                  />
                ) : (
                  ((TRESO_MODE && specialCategory) || !specialCategory) && (
                    <CustomDatePicker
                      errors={errors}
                      touched={touched}
                      style={{ width: "100%" }}
                      label="Date de trésorerie*"
                      name="dateTreso"
                      values={values?.dateTreso}
                      onFocus={() => handleInputFocus("dateTreso")}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      dropdownClassName="custom-datepicker-dropdown"
                    />
                  )
                )}
                {isDuplicated && type == "all" ? (
                  <CustomDateSelect
                    name="dateCompta"
                    displayValue={getDispalyedValueCompta({
                      displayValueCompta,
                      TRESO_MODE,
                      values,
                      getLastDayLabel,
                      matchingLabelCompta,
                    })}
                    value={values?.dateCompta}
                    specialCategory={specialCategory}
                    options={optionsCompta}
                    errors={errors}
                    touched={touched}
                    onChange={(changedValue) =>
                      handleChangeComptaDate(
                        changedValue,
                        setFieldValue,
                        monthDifferenceTreso,
                        setDisplayValueCompta,
                        TRESO_MODE,
                        COMPTA_MODE,
                        selectedDateTreso,
                        setSelectedDateCompta,
                        selectedDateCompta,
                        setSelectedDateTreso,
                        specialCategory,
                        edit,
                        displayValueTreso
                      )
                    }
                    onBlur={handleBlur}
                    onFocus={() => handleInputFocus("dateCompta")}
                    label={
                      COMPTA_MODE
                        ? "Jour du mois de comptabilité*"
                        : "Date de comptabilité (décalage)*"
                    }
                    condition={COMPTA_MODE}
                  />
                ) : (
                  ((COMPTA_MODE && specialCategory) || !specialCategory) && (
                    <>
                      <CustomDatePicker
                        label="Date de comptabilité* "
                        name="dateCompta"
                        values={values.dateCompta}
                        onFocus={() => handleInputFocus("dateCompta")}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                      />
                    </>
                  )
                )}
              </div>
              <div className="element-wrapper">
                <CustomSelect
                  label="Statut de trésorerie*"
                  name="status"
                  errors={errors}
                  touched={touched}
                  disabledOption={isDuplicated && type == "all" ? "pointé" : ""}
                  value={
                    values.type === "simulation" ? "simulation" : values?.status
                  }
                  disabled={
                    type === "simulation" ||
                    (COMPTA_MODE && specialCategory) ||
                    (TRESO_MODE && values.type === "simulation")
                  }
                  onChange={setFieldValue}
                  options={statusesArray}
                  condition={
                    (TRESO_MODE && specialCategory) || !specialCategory
                  }
                  showIcon
                />
                <CustomSelect
                  errors={errors}
                  touched={touched}
                  label="Type d'écriture*"
                  name="type"
                  value={values?.type}
                  disabled={!edit && isAction}
                  onChange={(_, value) => {
                    setFieldValue("type", value);
                    if (value === "simulation") {
                      setFieldValue("status", "prévisionnel");
                    }
                  }}
                  options={WRITING_TYPES}
                  disabledOption="soldé"
                  condition={
                    (COMPTA_MODE && specialCategory) || !specialCategory
                  }
                  showIcon
                />
              </div>
              <div className="submit-button-wrapper">
                <Button
                  text={isSubmitting ? "envoi en cours" : "Valider"}
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="submit-button"
                  allWidth
                  onClick={() => handleSubmitButtonState("validate")}
                />
                <Button
                  text="Annuler"
                  color="primary"
                  className="reset-button"
                  allWidth
                  onClick={(e) => {
                    onCancel();
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="validate">
                {!edit && (
                  <Button
                    type="submit"
                    text={
                      isSubmitting
                        ? "envoi en cours"
                        : "Valider et ajouter une nouvelle écriture"
                    }
                    disabled={isSubmitting}
                    color="primary"
                    className="validate-button"
                    allWidth
                    onClick={() => handleSubmitButtonState("submit")}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddWritingForm;
