import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";

const initialState = {
  isCreateProject: false,
  myProjects: [],
  project: null,
  deleteStatus: "idle",
  updateStatus: "idle",
  projectStatus: "idle",
  status: "idle",
  createStatus: "idle",
};

export const getMyProjects = createAsyncThunk(
  "project/getMyProjects",
  async () => {
    let data;
    try {
      const response = await axios.get(`api/projects`);
      data = await response.data;
      if ((response.status === 200)) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/projects/${id}`);
      data = await response.data;
      if ((response.status === 200)) {
        thunkAPI.dispatch(getMyProjects());
        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateProject = createAsyncThunk(
  "project/updateProject",
  async (body, thunkAPI) => {
    let data;
    const { id, projectName } = body;
    try {
      const response = await axios.put(`api/projects/${id}`, { projectName });
      data = await response.data;
      if (response.status === 200) {
        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getOneProject = createAsyncThunk(
  "project/getOneProject",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`api/projects/${id}`);
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);
export const createProject = createAsyncThunk(
  "project/createProject",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`api/projects`, body);
      data = await response.data;
      if ((response.status === 200)) {
        return {
          data: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const slice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: {
    [getMyProjects.pending]: (state) => {
      state.status = "loading";
    },
    [getMyProjects.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.myProjects = action.payload.data.data.docs;
    },
    [getMyProjects.failed]: (state, action) => {
      state.myProjects = "failed";
      state.error = action.error.message;
    },
    [deleteProject.pending]: (state) => {
      state.deleteStatus = "loading";
    },
    [deleteProject.fulfilled]: (state) => {
      state.deleteStatus = "succeeded";
    },
    [deleteProject.rejected]: (state) => {
      state.deleteStatus = "failed";
    },
    [updateProject.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateProject.fulfilled]: (state) => {
      state.updateStatus = "succeeded";
    },
    [updateProject.rejected]: (state) => {
      state.updateStatus = "failed";
    },
    [getOneProject.pending]: (state) => {
      state.projectStatus = "loading";
    },
    [getOneProject.fulfilled]: (state, action) => {
      state.projectStatus = "succeeded";
      state.project = action.payload.data.data;
    },
    [getOneProject.rejected]: (state) => {
      state.projectStatus = "failed";
    },
    [createProject.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createProject.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
    },
    [createProject.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
