import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";
import {
  groupElementsByDate,
  makeElementsEmpty,
} from "../../utilities/groupElementsByDate";
import {
  addIdKeyToCategories,
  calculateMonthTotals,
  createArrayOfObjects,
  getElementsPerRange,
  updateSynthesis,
} from "../../utilities/table";
import { openDeleteDialog } from "./table";
import { groupMonths } from "../../utilities/regroupment";
import { generateMonthList, defaultDate } from "../../utilities/filters";
import { addRacineToCatgories } from "../../utilities/tableUtils";

const { startDate, endDate } = defaultDate();

const initialState = {
  categories: [],
  category: null,
  selectedMonths: generateMonthList(startDate, endDate),
  filtersValues: {
    status: [],
    type: [],
  },
  categoryStatus: "idle",
  statusTofilter: null,
  typeToFilter: null,
  categoriesForSelect: [],
  status: "idle",
  exportStatus: "idle",
  createStatus: "idle",
  isDragStarted: false,
  createError: null,
  error: null,
  synthesisArr: [],
  getSynthesisStatus: "idle",
  synthesis: [],
  editStatus: "idle",
  editError: "",
  categoryToEdit: "",
  footerFiltersStatus: {
    status: "pointé",
  },
  footerFiltersTypes: {
    type: "simulation,réel",
  },
  RegroupmentValue: 1,
  parentKey: "",
  simulationElement: null,
  isCreatingInSimulation: false,
};

export const exportCategories = createAsyncThunk(
  "categories/exportCategories",
  async (queries, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `/api/categories/export/${queries.projectId}/${queries.mode}`,
        params: { ...queries.params, at: new Date().toLocaleString() },
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "categories.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (queries, thunkAPI) => {
    const noFilters =
      queries.params && !queries.params.status && !queries.params.type;

    let data;
    try {
      const response = await axios.get(
        `/api/categories/project/${queries.projectId}/${queries.mode}`,
        { params: queries.params }
      );

      data = await response.data;

      if (response.status === 200) {
        const arrayWithfilters = noFilters
          ? makeElementsEmpty(data.data)
          : data.data;
        const transformedData = groupElementsByDate(
          arrayWithfilters,
          queries.mode
        );
        return transformedData;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getSynthesis = createAsyncThunk(
  "categories/getSynthesis",
  async (queries, thunkAPI) => {
    const noFilters = !queries.params.status && !queries.params.type;
    if (noFilters) return { elementsSynthese: [] };

    let data;
    try {
      const response = await axios.get(
        `/api/synthese/${queries.mode}/${queries.projectId}`,
        { params: queries.params }
      );
      data = await response.data;

      if (response.status === 200) {
        return noFilters
          ? data.data.map((el) => (el.synthesis = 0))
          : data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getOneCategory = createAsyncThunk(
  "categories/getOneCategory",
  async (queries, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `api/categories/${queries.categoryId}/${queries.projectId}`
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (categoryData, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/categories/${categoryData?.id}`,
        categoryData?.values
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(deleteInput());
        thunkAPI
          .dispatch(getCategories(categoryData?.project))
          .then(() => thunkAPI.dispatch(getSynthesis(categoryData?.synthesis)));
        // .then(() => thunkAPI.dispatch(getSynthesis(categoryData?.synthesis)));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editCategory = createAsyncThunk(
  "categories/editCategory",
  async (values, thunkAPI) => {
    let data;

    try {
      const response = await axios.put(
        `/api/categories/${values.categoryId}`,
        values.values
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getCategoryToEdit(""));
        thunkAPI.dispatch(getCategories(values.project)).then(() => {
          thunkAPI.dispatch(getSynthesis(values?.synthesis));
        });

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async (values, thunkAPI) => {
    let data;

    try {
      const response = await axios.delete(
        `/api/categories/${values.categoryId}`
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(openDeleteDialog(null));
        thunkAPI
          .dispatch(getCategories(values?.project))
          .then(() => thunkAPI.dispatch(getSynthesis(values?.synthesis)));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setTypeToFilter: (state, action) => {
      state.typeToFilter = action.payload;
    },
    setMonthList: (state, action) => {
      state.selectedMonths = action.payload;
    },
    setStatusToFilter: (state, action) => {
      state.statusTofilter = action.payload;
    },
    addInput: (state, action) => {
      state.categories.push(action.payload);
    },
    setParentKey: (state, action) => {
      state.parentKey = action.payload;
    },
    setDragStarted: (state, action) => {
      state.isDragStarted = action.payload;
    },
    setFilters: (state, action) => {
      const payloadKeys = Object.keys(action.payload);
      if (payloadKeys.includes("status")) {
        const index = state.filtersValues.status.indexOf(action.payload.status);
        if (index !== -1) {
          state.filtersValues.status.splice(index, 1);
        } else {
          state.filtersValues.status.push(action.payload.status);
        }
      } else if (payloadKeys.includes("type")) {
        const index = state.filtersValues.type.indexOf(action.payload.type);
        if (index !== -1) {
          state.filtersValues.type.splice(index, 1);
        } else {
          state.filtersValues.type.push(action.payload.type);
        }
      }
    },
    resetFilters: (state, action) => {
      const newFiltersValues =
        action.payload === "treso"
          ? {
              status: ["prévisionnel", "engagé", "pointé"],
              type: ["simulation"],
            }
          : {
              status: [],
              type: ["simulation", "réel"],
            };
      state.filtersValues = newFiltersValues;
    },
    resetFiltersToNull: (state, action) => {
      state.filtersValues = { status: [], type: [] };
    },

    setFooterFilters: (state, action) => {
      if (action.payload.mode === "treso") {
        state.footerFiltersStatus = action.payload.filters;
        return;
      }
      state.footerFiltersTypes = action.payload.filters;
    },
    addInputWithCertainIndex: (state, action) => {
      function addObjectByIdInArray(arr, targetId, newObj) {
        for (const obj of arr) {
          if (obj.id === targetId) {
            if (!obj.children) {
              obj.children = [];
            }

            if (obj.children.length > 0) {
              const lastChild = obj.children[obj.children.length - 1];
              if (lastChild.isInput) {
                obj.children.pop();
                continue;
              }
            }
            obj.children.push(newObj);
          } else if (obj.children) {
            addObjectByIdInArray(obj.children, targetId, newObj);
          }
        }
      }

      return addObjectByIdInArray(
        state.categories,
        action.payload.id,
        action.payload.newObj
      );
    },
    deleteInput: (state, action) => {
      if (state.categories[state.categories?.length - 1]?.isInput) {
        state.categories.pop();
      }
    },
    getCategoryToEdit: (state, action) => {
      state.categoryToEdit = action.payload;
    },

    setRegroupmentValue: (state, action) => {
      state.RegroupmentValue = action.payload;
    },
    addElementInCategories: (state, action) => {
      function addObjectByIdInArray(arr, targetId) {
        for (const obj of arr) {
          if (obj.id === targetId) {
            if (!obj.children) {
              obj.children = [];
            }

            let indexOfLastChildWithoutName = -1;

            const existingCreateChildIndex = obj.children.findIndex(
              (child) => child.isCreate
            );

            if (existingCreateChildIndex === -1) {
              state.isCreatingInSimulation = true;
              if (obj.children.length > 0) {
                for (let i = obj.children.length - 1; i >= 0; i--) {
                  const child = obj.children[i];
                  if (!child.name) {
                    indexOfLastChildWithoutName = i;
                    break;
                  }
                }
              }

              if (indexOfLastChildWithoutName === -1) {
                obj.children.splice(0, 0, {
                  dataIndex: action.payload.dataIndex,
                  category: action.payload.category,
                  isCreate: true,
                });
              }

              if (indexOfLastChildWithoutName !== -1) {
                obj.children.splice(indexOfLastChildWithoutName + 1, 0, {
                  dataIndex: action.payload.dataIndex,
                  category: action.payload.category,
                  isCreate: true,
                });
              }
            } else {
              state.isCreatingInSimulation = false;
              obj.children.splice(existingCreateChildIndex, 1);
            }
          } else if (obj.children) {
            addObjectByIdInArray(obj.children, targetId);
          }
        }
      }

      return addObjectByIdInArray(state.categories, action.payload.category.id);
    },
  },
  extraReducers: {
    [exportCategories.pending]: (state) => {
      state.exportStatus = "loading";
    },
    [exportCategories.fulfilled]: (state) => {
      state.exportStatus = "succeeded";
    },
    [exportCategories.rejected]: (state) => {
      state.exportStatus = "failed";
    },
    [getCategories.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCategories.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const categoriesWithKeys = addIdKeyToCategories(action.payload);

      const categoriesWithKeysCopy = JSON.parse(
        JSON.stringify(categoriesWithKeys)
      );

      state.categoriesForSelect = categoriesWithKeysCopy;

      const categoriesWithRacine = addRacineToCatgories(categoriesWithKeys);

      const monthsGrouped = groupMonths(
        state.RegroupmentValue,
        state.selectedMonths
      );

      const categoriesWithSum = calculateMonthTotals(
        categoriesWithRacine,
        monthsGrouped
      );

      function processCategory(category) {
        const currentCategory = { ...category };
        if (currentCategory.elements) {
          const elementsPerRange = getElementsPerRange(
            monthsGrouped,
            currentCategory.elements
          );
          const finalElements = createArrayOfObjects(elementsPerRange);

          if (currentCategory.children) {
            const childrenArray = [...currentCategory.children];
            const reversedArrayElements = finalElements.reverse();

            for (let i = 0; i < reversedArrayElements.length; i++) {
              const { children, ...category } = currentCategory;
              childrenArray.unshift({
                ...reversedArrayElements[i],
                id: `${currentCategory.id}-${i}`,
                category,
              });
            }
            currentCategory.children = childrenArray.map((childCategory) =>
              processCategory(childCategory)
            );
          }
        }
        return currentCategory;
      }

      const processedCategories = categoriesWithSum.map(processCategory);

      state.categories = processedCategories;
    },
    [getCategories.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getSynthesis.pending]: (state, action) => {
      state.synthesisStatus = "loading";
    },
    [getSynthesis.fulfilled]: (state, action) => {
      state.categories = updateSynthesis(state.categories, action.payload);
      state.synthesisStatus = "succeeded";
      state.synthesisArr = action.payload;
    },
    [getSynthesis.rejected]: (state, action) => {
      state.synthesisStatus = "failed";
      state.error = action.payload;
    },

    [getOneCategory.pending]: (state) => {
      state.categoryStatus = "loading";
    },
    [getOneCategory.fulfilled]: (state, action) => {
      state.categoryStatus = "succeeded";
      state.category = action.payload.data.data;
    },
    [getOneCategory.rejected]: (state) => {
      state.categoryStatus = "failed";
    },
    [createCategory.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createCategory.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
    },
    [createCategory.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload;
    },
    [editCategory.pending]: (state, action) => {
      state.editStatus = "loading";
    },
    [editCategory.rejected]: (state, action) => {
      state.editStatus = "failed";
    },
  },
});

export const {
  setStatusToFilter,
  setTypeToFilter,
  addInput,
  deleteInput,
  getCategoryToEdit,
  addInputWithCertainIndex,
  setParentKey,
  addElementInCategories,
  setFilters,
  setDragStarted,
  resetFilters,
  setMonthList,
  resetFiltersToNull,
  setRegroupmentValue,
  setFooterFilters,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
