const getFieldClasses = (values, isCatgeories) => {
  const specificClass = values ? "small" : "not-filled";
  return isCatgeories
    ? `form-element-input ${specificClass} `
    : `input-wrapper-writing form-element-input ${specificClass}`;
};
const getFieldCategoryStyle = () => {
  return {
    width: "100%",
    borderColor: "#000",
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "30px",
    display: "flex",
  };
};
export { getFieldClasses, getFieldCategoryStyle };
