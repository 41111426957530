import { useSelector } from "react-redux";
import {
  convertToYYYYMM,
  convertToYYYYMMDD,
  parseDate,
} from "../utilities/filters";
import { COMPTA_ELEMENTS, TRESO_ELEMENTS } from "../data/constants";

export const useFiltersAndDates = () => {
  const { mode, selectedMonths, selectedMonthsSynthesis } = useSelector(
    (state) => state.layout
  );

  const {
    footerFiltersStatus,
    footerFiltersTypes,
    filtersValues: originalFiltersValues,
  } = useSelector((state) => state.categories);

  const constructFilters = () => {
    const filtersToUse =
      mode === "treso" ? footerFiltersStatus : footerFiltersTypes;

    const elementToUse = mode === "treso" ? TRESO_ELEMENTS : COMPTA_ELEMENTS;
    const footerFiltersShouldBeActivated =
      elementToUse.length ===
      originalFiltersValues.type.length + originalFiltersValues.status.length;

    let updatedFiltersValues = { ...originalFiltersValues };
    const hasNonEmptyType = updatedFiltersValues.type.length !== 0;
    const hasNonEmptyStatus = updatedFiltersValues.status.length !== 0;
    const hasSimulation = updatedFiltersValues.type.includes("simulation");
    const hasEngageOrPointe =
      updatedFiltersValues.status.includes("engagé") ||
      updatedFiltersValues.status.includes("pointé");
    const hasNoPrev = !updatedFiltersValues.status.includes("prévisionnel");
    const isOnlyPrev =
      updatedFiltersValues.status.includes("prévisionnel") &&
      updatedFiltersValues.status.length === 1 &&
      !hasNonEmptyType;

    if (isOnlyPrev) {
      updatedFiltersValues = {
        type: ["réel"],
        status: [...updatedFiltersValues.status],
      };
    }

    if (
      hasNonEmptyType &&
      hasNonEmptyStatus &&
      hasSimulation &&
      hasEngageOrPointe &&
      hasNoPrev
    ) {
      updatedFiltersValues = {
        ...updatedFiltersValues,
        status: [...updatedFiltersValues.status],
      };
    }
    if (!isOnlyPrev) {
      if (hasEngageOrPointe || !hasNoPrev) {
        updatedFiltersValues = {
          ...updatedFiltersValues,
          type: [...updatedFiltersValues.type, "réel"],
        };
      }
    }

    let footerConstructedFilters;

    if (!footerFiltersShouldBeActivated) {
      if (mode === "treso") {
        footerConstructedFilters = {
          ...(updatedFiltersValues.status.length > 0 && {
            status: updatedFiltersValues.status.join(","),
          }),
          ...(updatedFiltersValues.type.length > 0 && {
            type: updatedFiltersValues.type.join(","),
          }),
        };
      } else {
        footerConstructedFilters = {
          ...(hasNonEmptyType && {
            type: updatedFiltersValues.type.join(","),
          }),
        };
      }
    } else {
      footerConstructedFilters = filtersToUse;
    }

    const nonEmptyFilter = hasNonEmptyType || hasNonEmptyStatus;

    const tableConstructedFilters =
      nonEmptyFilter && mode === "treso"
        ? {
            ...(updatedFiltersValues.status.length > 0
              ? {
                  status: updatedFiltersValues.status.join(","),
                }
              : {}),
            ...(updatedFiltersValues.type.length > 0
              ? {
                  type: updatedFiltersValues.type.join(","),
                }
              : {}),
          }
        : {
            ...(hasNonEmptyType && {
              type: updatedFiltersValues.type.join(","),
            }),
          };
    return { footerConstructedFilters, tableConstructedFilters };
  };

  const getStartAndEndDate = () => {
    const startDate = convertToYYYYMM(selectedMonths[0]);
    const endDate = convertToYYYYMM(selectedMonths[selectedMonths.length - 1]);

    return { startDate, endDate };
  };

  const getStartAndEndDateSynthesis = () => {
    const startDateSynthesis = convertToYYYYMM(selectedMonthsSynthesis[0]);
    const endDateSynthesis = convertToYYYYMM(
      selectedMonthsSynthesis[selectedMonthsSynthesis.length - 1]
    );

    return { startDateSynthesis, endDateSynthesis };
  };
  const getStartAndEndDateGraph = () => {
    const startDate = convertToYYYYMMDD(selectedMonths[0]);
    const endDate = convertToYYYYMMDD(
      selectedMonths[selectedMonths.length - 1],
      "lastDay"
    );

    return { startDate, endDate };
  };

  const getStartAndEndDateSideBar = () => {
    const start = selectedMonths?.map((month) => parseDate(month))[0];
    const end = selectedMonths?.map((month) => parseDate(month))[
      selectedMonths?.length - 1
    ];

    return { start, end };
  };
  return {
    constructFilters,
    getStartAndEndDate,
    getStartAndEndDateSynthesis,
    getStartAndEndDateGraph,
    getStartAndEndDateSideBar,
  };
};
