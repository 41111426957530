import React from "react";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const verifyEmailUrl = "/verify-email";
  const loginUrl = "/login";

  const { isAuthenticated, isVerified, isLogout } = useAuth();

  if (!isAuthenticated || isLogout) {
    return <Navigate to={loginUrl}></Navigate>;
  }

  if (!isVerified) {
    return (window.location = verifyEmailUrl);
  }
  return <>{children}</>;
};

export default AuthGuard;
